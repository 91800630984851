import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {makeStyles} from '@mui/styles';

import Typewriter from 'typewriter-effect';

import Icon_1 from '../Graphics/MK Ultra Productions Written Logo.png';
import Graphic_1 from '../Graphics/a.jpeg';
import Graphic_2 from '../Graphics/b.jpeg';


const useStyles = makeStyles((theme) => ({    
    home: {
        height: window.innerHeight * 4,
    },

    page1: {
        height: window.innerHeight,
        position: 'sticky',
        top: 0,
    },

    page2: {
        height: window.innerHeight,
        position: 'sticky',
        top: 0,
    },

    header: {
        height: window.innerHeight * 0.10,
        width: window.innerWidth,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Courier',
        fontSize: '2.75rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
        },
    },

    footer: {
        height: window.innerHeight * 0.10,
        width: window.innerWidth,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Courier',
        fontSize: '2.75rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
        },
    },

    typewriterBox: {
        height: '100%',
    },

    iconBox: {
        height: window.innerHeight * 0.10 * 0.61,
        width: window.innerHeight * 0.10 * 0.61,
        [theme.breakpoints.down('md')]: {
            height: window.innerHeight * 0.10 * 0.31,
            width: window.innerHeight * 0.10 * 0.31,
        },
        [theme.breakpoints.down('sm')]: {
            height: window.innerHeight * 0.10 * 0.25,
            width: window.innerHeight * 0.10 * 0.25,
        },
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    },

    content1: {
        height: window.innerHeight * 0.80,
        width: window.innerWidth,
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'space-evenly',
        justifyContent: 'space-evenly',
    },

    content2: {
        height: window.innerHeight * 0.80,
        width: window.innerWidth,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },

    textBox1: {
        height: '30%',
        width: '40%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Courier',
        fontSize: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
            height: '20%',
            width: '80%',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },

    textBox2: {
        height: '75%',
        width: '65%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Courier',
        fontSize: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },

    graphicBox: {
        height: '30%',
        width: '40%',
        [theme.breakpoints.down('md')]: {
            height: '20%',
            width: '80%',
        },
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    },

    buttonBox: {
        height: '25%',
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '60%',
        },
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-evenly',
    },

    graphic: {
        maxHeight: '100%',
        maxWidth: '100%',
    },

    icon: {
        maxHeight: '100%',
        maxWidth: '100%',
    },

    button: {
        fontFamily: 'Courier',
        fontSize: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        color: 'black',
    },

    copyright: {
        fontFamily: 'Courier',
        fontSize: '0.75rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.50rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.25rem',
        },
    },
}));

const Home = () => {
    const classes = useStyles();

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.scrollY / window.innerHeight;

        setScrollPosition(position);

        if (position > 0.1) {
            document.getElementsByClassName("Typewriter__cursor")[1].style.color = 'white';
        }

        else {
            document.getElementsByClassName("Typewriter__cursor")[1].style.color = 'black';
        }
    }

    const handleHeader = (typewriter) => {
        typewriter.typeString("MK").pauseFor(300);
        typewriter.typeString(" Ultra").pauseFor(300);
        typewriter.typeString(" Productions").pauseFor(300);
        typewriter.start();
    }
    
    const handleTextOpacity = (letter, letters, position) => {
        const offset = (position.endPosition - position.startPosition) / (letters);

        if (scrollPosition < position.startPosition + letter * offset) {
            return 0;
        }

        else {
            return 1;
        }
    }

    const handleTextBackgroundColor = (letter, letters, position, mode) => {
        if (mode === true) {
            const offset = (position.redactEndPosition - position.redactStartPosition) / (letters);

            if (scrollPosition < position.redactStartPosition + letter * offset) {
                return 'black';
            }

            else {
                return 'white';
            }
        }

        else {
            const offset = (position.endPosition - position.startPosition) / (letters);

            if (scrollPosition < position.startPosition + letter * offset) {
                return 'black';
            }

            else {
                return 'white';
            }
        }   
    }

    const handleImageOpacity = (position) => {
        if (scrollPosition < position.startPosition) {
            return 0;
        }

        else {
            return (position.endPosition - position.startPosition) * (scrollPosition - position.startPosition);
        }
    }

    const handleCursor = (value, letter, letters, position) => {
        if (value !== 'cursor') {
            if (handleTextOpacity(letter - 1, letters, position) && (!handleTextOpacity(letter, letters, position)) && (letter !== 0)) {
                return <span style = {{display: 'inline-block', width: handleCursorWidth(letter === ' ')}}><Typewriter onInit = {(typewriter) => {typewriter.start()}} /></span>;
            }

            else {
                return value;
            }
        }

        else {
            if (handleTextOpacity(letter - 1, letters, position)) {
                return <span style = {{display: 'inline-block', width: handleCursorWidth(0)}}><Typewriter onInit = {(typewriter) => {typewriter.start()}} /></span>;
            }

            else {
                return 'a';
            }
        }
    }

    const handleCursorWidth = (type) => {
        try {
            if (type === 0) {
                return document.getElementById('char').offsetWidth;
            }
    
            else {
                return document.getElementById('space').offsetWidth;
            }
        }

        catch {

        }

        return 0;
    }

    const handleCursorOpacity = (letter, letters, position1, position2) => {
        if (handleTextOpacity(letter, letters, position1)) {
            if (scrollPosition < position2.startPosition) {
                return 1;
            }

            else {
                return 0;
            }
        }

        else {
            return 0;
        }
    }

    const handleButtonOpacity = (position) => {
        if (scrollPosition < position.startPosition) {
            return 0;
        }

        else {
            return (position.endPosition - position.startPosition) * (scrollPosition - position.startPosition);
        }
    }

    const handleOrder = (type) => {
        if (type === 0) {
            if (window.innerWidth > 900) {
                return 3;
            }

            else {
                return 4;
            }
        }

        else {
            if (window.innerWidth > 900) {
                return 4;
            }

            else {
                return 3;
            }
        }
    }

    const Icon = () => {
        return (
            <div>
                <Button className = {classes.icon} component = {Link} to = '/'>
                    <img className = {classes.icon} src = {Icon_1} alt = '' />
                </Button>
            </div>
        )
    }

    const Text1 = (position) => {
        return (
            <>
                <span style = {{opacity: handleTextOpacity(0, 83, position)}}>{handleCursor('W', 0, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(0, 83, position)}}>{handleCursor('h', 1, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(1, 83, position)}}>{handleCursor('e', 2, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(2, 83, position)}}>{handleCursor('n', 3, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(3, 83, position)}}>{handleCursor(' ', 4, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(4, 83, position)}}>{handleCursor('n', 5, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(5, 83, position)}}>{handleCursor('o', 6, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(6, 83, position)}}>{handleCursor('b', 7, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(7, 83, position)}}>{handleCursor('o', 8, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(8, 83, position)}}>{handleCursor('d', 9, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(9, 83, position)}}>{handleCursor('y', 10, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(10, 83, position)}}>{handleCursor(' ', 11, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(11, 83, position)}}>{handleCursor('i', 12, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(12, 83, position)}}>{handleCursor('s', 13, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(13, 83, position)}}>{handleCursor(' ', 14, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(14, 83, position)}}>{handleCursor('a', 15, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(15, 83, position)}}>{handleCursor('r', 16, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(16, 83, position)}}>{handleCursor('o', 17, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(17, 83, position)}}>{handleCursor('u', 18, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(18, 83, position)}}>{handleCursor('n', 19, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(19, 83, position)}}>{handleCursor('d', 20, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(20, 83, position)}}>{handleCursor(',', 21, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(21, 83, position)}}>{handleCursor(' ', 22, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(22, 83, position)}}>{handleCursor('t', 23, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(23, 83, position)}}>{handleCursor('h', 24, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(24, 83, position)}}>{handleCursor('e', 25, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(25, 83, position)}}>{handleCursor(' ', 26, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(26, 83, position)}}>{handleCursor('t', 27, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(27, 83, position)}}>{handleCursor('r', 28, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(28, 83, position)}}>{handleCursor('e', 29, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(29, 83, position)}}>{handleCursor('e', 30, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(30, 83, position)}}>{handleCursor('s', 31, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(31, 83, position)}}>{handleCursor(' ', 32, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(32, 83, position)}}>{handleCursor('g', 33, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(33, 83, position)}}>{handleCursor('o', 34, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(34, 83, position)}}>{handleCursor('s', 35, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(35, 83, position)}}>{handleCursor('s', 36, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(36, 83, position)}}>{handleCursor('i', 37, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(37, 83, position)}}>{handleCursor('p', 38, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(38, 83, position)}}>{handleCursor(' ', 39, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(39, 83, position)}}>{handleCursor('a', 40, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(40, 83, position)}}>{handleCursor('b', 41, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(41, 83, position)}}>{handleCursor('o', 42, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(42, 83, position)}}>{handleCursor('u', 43, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(43, 83, position)}}>{handleCursor('t', 44, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(44, 83, position)}}>{handleCursor(' ', 45, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(45, 83, position)}}>{handleCursor('t', 46, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(46, 83, position)}}>{handleCursor('h', 47, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(47, 83, position)}}>{handleCursor('e', 48, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(48, 83, position)}}>{handleCursor(' ', 49, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(49, 83, position)}}>{handleCursor('p', 50, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(50, 83, position)}}>{handleCursor('e', 51, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(51, 83, position)}}>{handleCursor('o', 52, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(52, 83, position)}}>{handleCursor('p', 53, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(53, 83, position)}}>{handleCursor('l', 54, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(54, 83, position)}}>{handleCursor('e', 55, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(55, 83, position)}}>{handleCursor(' ', 56, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(56, 83, position)}}>{handleCursor('w', 57, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(57, 83, position)}}>{handleCursor('h', 58, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(58, 83, position)}}>{handleCursor('o', 59, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(59, 83, position)}}>{handleCursor(' ', 60, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(60, 83, position)}}>{handleCursor('h', 61, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(61, 83, position)}}>{handleCursor('a', 62, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(62, 83, position)}}>{handleCursor('v', 63, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(63, 83, position)}}>{handleCursor('e', 64, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(64, 83, position)}}>{handleCursor(' ', 65, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(65, 83, position)}}>{handleCursor('w', 66, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(66, 83, position)}}>{handleCursor('a', 67, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(67, 83, position)}}>{handleCursor('l', 68, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(68, 83, position)}}>{handleCursor('k', 69, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(69, 83, position)}}>{handleCursor('e', 70, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(70, 83, position)}}>{handleCursor('d', 71, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(71, 83, position)}}>{handleCursor(' ', 72, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(72, 83, position)}}>{handleCursor('u', 73, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(73, 83, position)}}>{handleCursor('n', 74, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(74, 83, position)}}>{handleCursor('d', 75, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(75, 83, position)}}>{handleCursor('e', 76, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(76, 83, position)}}>{handleCursor('r', 77, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(77, 83, position)}}>{handleCursor(' ', 78, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(78, 83, position)}}>{handleCursor('t', 79, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(79, 83, position)}}>{handleCursor('h', 80, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(80, 83, position)}}>{handleCursor('e', 81, 83, position)}</span>
                <span style = {{opacity: handleTextOpacity(81, 83, position)}}>{handleCursor('m', 82, 83, position)}</span>
            </>
        )
    }

    const Text2 = (position) => {
        return (
            <>
                <span style = {{opacity: handleTextOpacity(0, 89, position)}}>{handleCursor('I', 0, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(0, 89, position)}}>{handleCursor("'", 1, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(1, 89, position)}}>{handleCursor('m', 2, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(2, 89, position)}}>{handleCursor(' ', 3, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(3, 89, position)}}>{handleCursor('w', 4, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(4, 89, position)}}>{handleCursor('o', 5, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(5, 89, position)}}>{handleCursor('r', 6, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(6, 89, position)}}>{handleCursor('r', 7, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(7, 89, position)}}>{handleCursor('i', 8, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(8, 89, position)}}>{handleCursor('e', 9, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(9, 89, position)}}>{handleCursor('d', 10, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(10, 89, position)}}>{handleCursor(' ', 11, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(11, 89, position)}}>{handleCursor('b', 12, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(12, 89, position)}}>{handleCursor('y', 13, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(13, 89, position)}}>{handleCursor(' ', 14, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(14, 89, position)}}>{handleCursor('t', 15, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(15, 89, position)}}>{handleCursor('h', 16, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(16, 89, position)}}>{handleCursor('e', 17, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(17, 89, position)}}>{handleCursor(' ', 18, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(18, 89, position)}}>{handleCursor('f', 19, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(19, 89, position)}}>{handleCursor('a', 20, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(20, 89, position)}}>{handleCursor('c', 21, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(21, 89, position)}}>{handleCursor('t', 22, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(22, 89, position)}}>{handleCursor(' ', 23, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(23, 89, position)}}>{handleCursor('t', 24, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(24, 89, position)}}>{handleCursor('h', 25, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(25, 89, position)}}>{handleCursor('a', 26, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(26, 89, position)}}>{handleCursor('t', 27, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(27, 89, position)}}>{handleCursor(' ', 28, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(28, 89, position)}}>{handleCursor('m', 29, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(29, 89, position)}}>{handleCursor('y', 30, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(30, 89, position)}}>{handleCursor(' ', 31, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(31, 89, position)}}>{handleCursor('d', 32, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(32, 89, position)}}>{handleCursor('a', 33, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(33, 89, position)}}>{handleCursor('u', 34, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(34, 89, position)}}>{handleCursor('g', 35, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(35, 89, position)}}>{handleCursor('h', 36, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(36, 89, position)}}>{handleCursor('t', 37, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(37, 89, position)}}>{handleCursor('e', 38, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(38, 89, position)}}>{handleCursor('r', 39, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(39, 89, position)}}>{handleCursor(' ', 40, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(40, 89, position)}}>{handleCursor('l', 41, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(41, 89, position)}}>{handleCursor('o', 42, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(42, 89, position)}}>{handleCursor('o', 43, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(43, 89, position)}}>{handleCursor('k', 44, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(44, 89, position)}}>{handleCursor('s', 45, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(45, 89, position)}}>{handleCursor(' ', 46, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(46, 89, position)}}>{handleCursor('t', 47, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(47, 89, position)}}>{handleCursor('o', 48, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(48, 89, position)}}>{handleCursor(' ', 49, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(49, 89, position)}}>{handleCursor('t', 50, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(50, 89, position)}}>{handleCursor('h', 51, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(51, 89, position)}}>{handleCursor('e', 52, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(52, 89, position)}}>{handleCursor(' ', 53, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(53, 89, position)}}>{handleCursor('l', 54, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(54, 89, position)}}>{handleCursor('o', 55, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(55, 89, position)}}>{handleCursor('c', 56, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(56, 89, position)}}>{handleCursor('a', 57, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(57, 89, position)}}>{handleCursor('l', 58, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(58, 89, position)}}>{handleCursor(' ', 59, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(59, 89, position)}}>{handleCursor('c', 60, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(60, 89, position)}}>{handleCursor('a', 61, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(61, 89, position)}}>{handleCursor('r', 62, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(62, 89, position)}}>{handleCursor('p', 63, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(63, 89, position)}}>{handleCursor('e', 64, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(64, 89, position)}}>{handleCursor('t', 65, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(65, 89, position)}}>{handleCursor(' ', 66, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(66, 89, position)}}>{handleCursor('s', 67, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(67, 89, position)}}>{handleCursor('e', 68, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(68, 89, position)}}>{handleCursor('l', 69, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(69, 89, position)}}>{handleCursor('l', 70, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(70, 89, position)}}>{handleCursor('e', 71, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(71, 89, position)}}>{handleCursor('r', 72, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(72, 89, position)}}>{handleCursor(' ', 73, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(73, 89, position)}}>{handleCursor('a', 74, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(74, 89, position)}}>{handleCursor('s', 75, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(75, 89, position)}}>{handleCursor(' ', 76, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(76, 89, position)}}>{handleCursor('a', 77, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(77, 89, position)}}>{handleCursor(' ', 78, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(78, 89, position)}}>{handleCursor('r', 79, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(79, 89, position)}}>{handleCursor('o', 80, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(80, 89, position)}}>{handleCursor('l', 81, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(81, 89, position)}}>{handleCursor('e', 82, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(82, 89, position)}}>{handleCursor(' ', 83, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(83, 89, position)}}>{handleCursor('m', 84, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(84, 89, position)}}>{handleCursor('o', 85, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(85, 89, position)}}>{handleCursor('d', 86, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(86, 89, position)}}>{handleCursor('e', 87, 89, position)}</span>
                <span style = {{opacity: handleTextOpacity(87, 89, position)}}>{handleCursor('l', 88, 89, position)}</span>
            </>
        )
    }

    const Text3 = (position) => {
        return (
            <>
                <span style = {{opacity: handleTextOpacity(0, 250, position), backgroundColor: handleTextBackgroundColor(0, 250, position, false)}}>{handleCursor('H', 0, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(0, 250, position), backgroundColor: handleTextBackgroundColor(1, 250, position, false)}}>{handleCursor('e', 1, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(1, 250, position), backgroundColor: handleTextBackgroundColor(2, 250, position, false)}}>{handleCursor(' ', 2, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(2, 250, position), backgroundColor: handleTextBackgroundColor(3, 250, position, false)}}>{handleCursor('e', 3, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(3, 250, position), backgroundColor: handleTextBackgroundColor(4, 250, position, false)}}>{handleCursor('n', 4, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(4, 250, position), backgroundColor: handleTextBackgroundColor(5, 250, position, false)}}>{handleCursor('d', 5, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(5, 250, position), backgroundColor: handleTextBackgroundColor(6, 250, position, false)}}>{handleCursor('e', 6, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(6, 250, position), backgroundColor: handleTextBackgroundColor(7, 250, position, false)}}>{handleCursor('d', 7, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(7, 250, position), backgroundColor: handleTextBackgroundColor(8, 250, position, false)}}>{handleCursor(' ', 8, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(8, 250, position), backgroundColor: handleTextBackgroundColor(9, 250, position, false)}}>{handleCursor('u', 9, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(9, 250, position), backgroundColor: handleTextBackgroundColor(10, 250, position, false)}}>{handleCursor('p', 10, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(10, 250, position), backgroundColor: handleTextBackgroundColor(11, 250, position, false)}}>{handleCursor(' ', 11, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(11, 250, position), backgroundColor: handleTextBackgroundColor(12, 250, position, true)}}>{handleCursor('b', 12, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(12, 250, position), backgroundColor: handleTextBackgroundColor(13, 250, position, true)}}>{handleCursor('u', 13, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(13, 250, position), backgroundColor: handleTextBackgroundColor(14, 250, position, true)}}>{handleCursor('r', 14, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(14, 250, position), backgroundColor: handleTextBackgroundColor(15, 250, position, true)}}>{handleCursor('n', 15, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(15, 250, position), backgroundColor: handleTextBackgroundColor(16, 250, position, true)}}>{handleCursor('i', 16, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(16, 250, position), backgroundColor: handleTextBackgroundColor(17, 250, position, true)}}>{handleCursor('n', 17, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(17, 250, position), backgroundColor: handleTextBackgroundColor(18, 250, position, true)}}>{handleCursor('g', 18, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(18, 250, position), backgroundColor: handleTextBackgroundColor(19, 250, position, false)}}>{handleCursor(' ', 19, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(19, 250, position), backgroundColor: handleTextBackgroundColor(20, 250, position, false)}}>{handleCursor('h', 20, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(20, 250, position), backgroundColor: handleTextBackgroundColor(21, 250, position, false)}}>{handleCursor('i', 21, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(21, 250, position), backgroundColor: handleTextBackgroundColor(22, 250, position, false)}}>{handleCursor('s', 22, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(22, 250, position), backgroundColor: handleTextBackgroundColor(23, 250, position, false)}}>{handleCursor(' ', 23, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(23, 250, position), backgroundColor: handleTextBackgroundColor(24, 250, position, false)}}>{handleCursor('f', 24, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(24, 250, position), backgroundColor: handleTextBackgroundColor(25, 250, position, false)}}>{handleCursor('i', 25, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(25, 250, position), backgroundColor: handleTextBackgroundColor(26, 250, position, false)}}>{handleCursor('n', 26, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(26, 250, position), backgroundColor: handleTextBackgroundColor(27, 250, position, false)}}>{handleCursor('g', 27, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(27, 250, position), backgroundColor: handleTextBackgroundColor(28, 250, position, false)}}>{handleCursor('e', 28, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(28, 250, position), backgroundColor: handleTextBackgroundColor(29, 250, position, false)}}>{handleCursor('r', 29, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(29, 250, position), backgroundColor: handleTextBackgroundColor(30, 250, position, false)}}>{handleCursor('s', 30, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(30, 250, position), backgroundColor: handleTextBackgroundColor(31, 250, position, false)}}>{handleCursor(' ', 31, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(31, 250, position), backgroundColor: handleTextBackgroundColor(32, 250, position, false)}}>{handleCursor('p', 32, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(32, 250, position), backgroundColor: handleTextBackgroundColor(33, 250, position, false)}}>{handleCursor('o', 33, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(33, 250, position), backgroundColor: handleTextBackgroundColor(34, 250, position, false)}}>{handleCursor('k', 34, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(34, 250, position), backgroundColor: handleTextBackgroundColor(35, 250, position, false)}}>{handleCursor('i', 35, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(35, 250, position), backgroundColor: handleTextBackgroundColor(36, 250, position, false)}}>{handleCursor('n', 36, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(36, 250, position), backgroundColor: handleTextBackgroundColor(37, 250, position, false)}}>{handleCursor('g', 37, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(37, 250, position), backgroundColor: handleTextBackgroundColor(38, 250, position, false)}}>{handleCursor(' ', 38, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(38, 250, position), backgroundColor: handleTextBackgroundColor(39, 250, position, false)}}>{handleCursor('s', 39, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(39, 250, position), backgroundColor: handleTextBackgroundColor(40, 250, position, false)}}>{handleCursor('o', 40, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(40, 250, position), backgroundColor: handleTextBackgroundColor(41, 250, position, false)}}>{handleCursor('m', 41, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(41, 250, position), backgroundColor: handleTextBackgroundColor(42, 250, position, false)}}>{handleCursor('e', 42, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(42, 250, position), backgroundColor: handleTextBackgroundColor(43, 250, position, false)}}>{handleCursor('o', 43, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(43, 250, position), backgroundColor: handleTextBackgroundColor(44, 250, position, false)}}>{handleCursor('n', 44, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(44, 250, position), backgroundColor: handleTextBackgroundColor(45, 250, position, false)}}>{handleCursor('e', 45, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(45, 250, position), backgroundColor: handleTextBackgroundColor(46, 250, position, false)}}>{handleCursor(' ', 46, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(46, 250, position), backgroundColor: handleTextBackgroundColor(47, 250, position, false)}}>{handleCursor('e', 47, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(47, 250, position), backgroundColor: handleTextBackgroundColor(48, 250, position, false)}}>{handleCursor('l', 48, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(48, 250, position), backgroundColor: handleTextBackgroundColor(49, 250, position, false)}}>{handleCursor('s', 49, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(49, 250, position), backgroundColor: handleTextBackgroundColor(50, 250, position, false)}}>{handleCursor('e', 50, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(50, 250, position), backgroundColor: handleTextBackgroundColor(51, 250, position, false)}}>{handleCursor("'", 51, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(51, 250, position), backgroundColor: handleTextBackgroundColor(52, 250, position, false)}}>{handleCursor('s', 52, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(52, 250, position), backgroundColor: handleTextBackgroundColor(53, 250, position, false)}}>{handleCursor(' ', 53, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(53, 250, position), backgroundColor: handleTextBackgroundColor(54, 250, position, false)}}>{handleCursor('f', 54, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(54, 250, position), backgroundColor: handleTextBackgroundColor(55, 250, position, false)}}>{handleCursor('i', 55, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(55, 250, position), backgroundColor: handleTextBackgroundColor(56, 250, position, false)}}>{handleCursor('r', 56, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(56, 250, position), backgroundColor: handleTextBackgroundColor(57, 250, position, false)}}>{handleCursor('e', 57, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(57, 250, position), backgroundColor: handleTextBackgroundColor(58, 250, position, false)}}>{handleCursor('.', 58, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(58, 250, position), backgroundColor: handleTextBackgroundColor(59, 250, position, false)}}>{handleCursor(' ', 59, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(59, 250, position), backgroundColor: handleTextBackgroundColor(60, 250, position, false)}}>{handleCursor('W', 60, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(60, 250, position), backgroundColor: handleTextBackgroundColor(61, 250, position, false)}}>{handleCursor('e', 61, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(61, 250, position), backgroundColor: handleTextBackgroundColor(62, 250, position, false)}}>{handleCursor(' ', 62, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(62, 250, position), backgroundColor: handleTextBackgroundColor(63, 250, position, false)}}>{handleCursor('w', 63, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(63, 250, position), backgroundColor: handleTextBackgroundColor(64, 250, position, false)}}>{handleCursor('i', 64, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(64, 250, position), backgroundColor: handleTextBackgroundColor(65, 250, position, false)}}>{handleCursor('l', 65, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(65, 250, position), backgroundColor: handleTextBackgroundColor(66, 250, position, false)}}>{handleCursor('l', 66, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(66, 250, position), backgroundColor: handleTextBackgroundColor(67, 250, position, false)}}>{handleCursor(' ', 67, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(67, 250, position), backgroundColor: handleTextBackgroundColor(68, 250, position, false)}}>{handleCursor('n', 68, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(68, 250, position), backgroundColor: handleTextBackgroundColor(69, 250, position, false)}}>{handleCursor('o', 69, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(69, 250, position), backgroundColor: handleTextBackgroundColor(70, 250, position, false)}}>{handleCursor('t', 70, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(70, 250, position), backgroundColor: handleTextBackgroundColor(71, 250, position, false)}}>{handleCursor(' ', 71, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(71, 250, position), backgroundColor: handleTextBackgroundColor(72, 250, position, true)}}>{handleCursor('a', 72, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(72, 250, position), backgroundColor: handleTextBackgroundColor(73, 250, position, true)}}>{handleCursor('l', 73, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(73, 250, position), backgroundColor: handleTextBackgroundColor(74, 250, position, true)}}>{handleCursor('l', 74, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(74, 250, position), backgroundColor: handleTextBackgroundColor(75, 250, position, true)}}>{handleCursor('o', 75, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(75, 250, position), backgroundColor: handleTextBackgroundColor(76, 250, position, true)}}>{handleCursor('w', 76, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(76, 250, position), backgroundColor: handleTextBackgroundColor(77, 250, position, false)}}>{handleCursor(' ', 77, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(77, 250, position), backgroundColor: handleTextBackgroundColor(78, 250, position, false)}}>{handleCursor('y', 78, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(78, 250, position), backgroundColor: handleTextBackgroundColor(79, 250, position, false)}}>{handleCursor('o', 79, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(79, 250, position), backgroundColor: handleTextBackgroundColor(80, 250, position, false)}}>{handleCursor('u', 80, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(80, 250, position), backgroundColor: handleTextBackgroundColor(81, 250, position, false)}}>{handleCursor(' ', 81, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(81, 250, position), backgroundColor: handleTextBackgroundColor(82, 250, position, false)}}>{handleCursor('t', 82, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(82, 250, position), backgroundColor: handleTextBackgroundColor(83, 250, position, false)}}>{handleCursor('o', 83, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(83, 250, position), backgroundColor: handleTextBackgroundColor(84, 250, position, false)}}>{handleCursor(' ', 84, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(84, 250, position), backgroundColor: handleTextBackgroundColor(85, 250, position, false)}}>{handleCursor('b', 85, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(85, 250, position), backgroundColor: handleTextBackgroundColor(86, 250, position, false)}}>{handleCursor('r', 86, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(86, 250, position), backgroundColor: handleTextBackgroundColor(87, 250, position, false)}}>{handleCursor('i', 87, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(87, 250, position), backgroundColor: handleTextBackgroundColor(88, 250, position, false)}}>{handleCursor('n', 88, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(88, 250, position), backgroundColor: handleTextBackgroundColor(89, 250, position, false)}}>{handleCursor('g', 89, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(89, 250, position), backgroundColor: handleTextBackgroundColor(90, 250, position, false)}}>{handleCursor(' ', 90, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(90, 250, position), backgroundColor: handleTextBackgroundColor(91, 250, position, false)}}>{handleCursor('y', 91, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(91, 250, position), backgroundColor: handleTextBackgroundColor(92, 250, position, false)}}>{handleCursor('o', 92, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(92, 250, position), backgroundColor: handleTextBackgroundColor(93, 250, position, false)}}>{handleCursor('u', 93, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(93, 250, position), backgroundColor: handleTextBackgroundColor(94, 250, position, false)}}>{handleCursor('r', 94, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(94, 250, position), backgroundColor: handleTextBackgroundColor(95, 250, position, false)}}>{handleCursor(' ', 95, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(95, 250, position), backgroundColor: handleTextBackgroundColor(96, 250, position, false)}}>{handleCursor('p', 96, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(96, 250, position), backgroundColor: handleTextBackgroundColor(97, 250, position, false)}}>{handleCursor('e', 97, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(97, 250, position), backgroundColor: handleTextBackgroundColor(98, 250, position, false)}}>{handleCursor('t', 98, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(98, 250, position), backgroundColor: handleTextBackgroundColor(99, 250, position, false)}}>{handleCursor(' ', 99, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(99, 250, position), backgroundColor: handleTextBackgroundColor(100, 250, position, true)}}>{handleCursor('a', 100, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(100, 250, position), backgroundColor: handleTextBackgroundColor(101, 250, position, true)}}>{handleCursor('r', 101, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(101, 250, position), backgroundColor: handleTextBackgroundColor(102, 250, position, true)}}>{handleCursor('m', 102, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(102, 250, position), backgroundColor: handleTextBackgroundColor(103, 250, position, true)}}>{handleCursor('a', 103, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(103, 250, position), backgroundColor: handleTextBackgroundColor(104, 250, position, true)}}>{handleCursor('d', 104, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(104, 250, position), backgroundColor: handleTextBackgroundColor(105, 250, position, true)}}>{handleCursor('i', 105, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(105, 250, position), backgroundColor: handleTextBackgroundColor(106, 250, position, true)}}>{handleCursor('l', 106, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(106, 250, position), backgroundColor: handleTextBackgroundColor(107, 250, position, true)}}>{handleCursor('l', 107, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(107, 250, position), backgroundColor: handleTextBackgroundColor(108, 250, position, true)}}>{handleCursor('o', 108, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(108, 250, position), backgroundColor: handleTextBackgroundColor(109, 250, position, false)}}>{handleCursor(' ', 109, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(109, 250, position), backgroundColor: handleTextBackgroundColor(110, 250, position, false)}}>{handleCursor('a', 110, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(110, 250, position), backgroundColor: handleTextBackgroundColor(111, 250, position, false)}}>{handleCursor('l', 111, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(111, 250, position), backgroundColor: handleTextBackgroundColor(112, 250, position, false)}}>{handleCursor('o', 112, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(112, 250, position), backgroundColor: handleTextBackgroundColor(113, 250, position, false)}}>{handleCursor('n', 113, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(113, 250, position), backgroundColor: handleTextBackgroundColor(114, 250, position, false)}}>{handleCursor('g', 114, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(114, 250, position), backgroundColor: handleTextBackgroundColor(115, 250, position, false)}}>{handleCursor('.', 115, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(115, 250, position), backgroundColor: handleTextBackgroundColor(116, 250, position, false)}}>{handleCursor(' ', 116, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(116, 250, position), backgroundColor: handleTextBackgroundColor(117, 250, position, false)}}>{handleCursor('H', 117, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(117, 250, position), backgroundColor: handleTextBackgroundColor(118, 250, position, false)}}>{handleCursor('e', 118, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(118, 250, position), backgroundColor: handleTextBackgroundColor(119, 250, position, false)}}>{handleCursor(' ', 119, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(119, 250, position), backgroundColor: handleTextBackgroundColor(120, 250, position, false)}}>{handleCursor('s', 120, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(120, 250, position), backgroundColor: handleTextBackgroundColor(121, 250, position, false)}}>{handleCursor('w', 121, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(121, 250, position), backgroundColor: handleTextBackgroundColor(122, 250, position, false)}}>{handleCursor('o', 122, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(122, 250, position), backgroundColor: handleTextBackgroundColor(123, 250, position, false)}}>{handleCursor('r', 123, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(123, 250, position), backgroundColor: handleTextBackgroundColor(124, 250, position, false)}}>{handleCursor('e', 124, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(124, 250, position), backgroundColor: handleTextBackgroundColor(125, 250, position, false)}}>{handleCursor(' ', 125, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(125, 250, position), backgroundColor: handleTextBackgroundColor(126, 250, position, false)}}>{handleCursor('h', 126, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(126, 250, position), backgroundColor: handleTextBackgroundColor(127, 250, position, false)}}>{handleCursor('e', 127, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(127, 250, position), backgroundColor: handleTextBackgroundColor(128, 250, position, false)}}>{handleCursor(' ', 128, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(128, 250, position), backgroundColor: handleTextBackgroundColor(129, 250, position, false)}}>{handleCursor('j', 129, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(129, 250, position), backgroundColor: handleTextBackgroundColor(130, 250, position, false)}}>{handleCursor('u', 130, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(130, 250, position), backgroundColor: handleTextBackgroundColor(131, 250, position, false)}}>{handleCursor('s', 131, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(131, 250, position), backgroundColor: handleTextBackgroundColor(132, 250, position, false)}}>{handleCursor('t', 132, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(132, 250, position), backgroundColor: handleTextBackgroundColor(133, 250, position, false)}}>{handleCursor(' ', 133, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(133, 250, position), backgroundColor: handleTextBackgroundColor(134, 250, position, false)}}>{handleCursor('s', 134, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(134, 250, position), backgroundColor: handleTextBackgroundColor(135, 250, position, false)}}>{handleCursor('a', 135, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(135, 250, position), backgroundColor: handleTextBackgroundColor(136, 250, position, false)}}>{handleCursor('w', 136, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(136, 250, position), backgroundColor: handleTextBackgroundColor(137, 250, position, false)}}>{handleCursor(' ', 137, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(137, 250, position), backgroundColor: handleTextBackgroundColor(138, 250, position, false)}}>{handleCursor('h', 138, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(138, 250, position), backgroundColor: handleTextBackgroundColor(139, 250, position, false)}}>{handleCursor('i', 139, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(139, 250, position), backgroundColor: handleTextBackgroundColor(140, 250, position, false)}}>{handleCursor('s', 140, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(140, 250, position), backgroundColor: handleTextBackgroundColor(141, 250, position, false)}}>{handleCursor(' ', 141, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(141, 250, position), backgroundColor: handleTextBackgroundColor(142, 250, position, false)}}>{handleCursor('s', 142, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(142, 250, position), backgroundColor: handleTextBackgroundColor(143, 250, position, false)}}>{handleCursor('u', 143, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(143, 250, position), backgroundColor: handleTextBackgroundColor(144, 250, position, false)}}>{handleCursor('s', 144, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(144, 250, position), backgroundColor: handleTextBackgroundColor(145, 250, position, false)}}>{handleCursor('h', 145, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(145, 250, position), backgroundColor: handleTextBackgroundColor(146, 250, position, false)}}>{handleCursor('i', 146, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(146, 250, position), backgroundColor: handleTextBackgroundColor(147, 250, position, false)}}>{handleCursor(' ', 147, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(147, 250, position), backgroundColor: handleTextBackgroundColor(148, 250, position, false)}}>{handleCursor('m', 148, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(148, 250, position), backgroundColor: handleTextBackgroundColor(149, 250, position, false)}}>{handleCursor('o', 149, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(149, 250, position), backgroundColor: handleTextBackgroundColor(150, 250, position, false)}}>{handleCursor('v', 150, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(150, 250, position), backgroundColor: handleTextBackgroundColor(151, 250, position, false)}}>{handleCursor('e', 151, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(151, 250, position), backgroundColor: handleTextBackgroundColor(152, 250, position, false)}}>{handleCursor('.', 152, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(152, 250, position), backgroundColor: handleTextBackgroundColor(153, 250, position, false)}}>{handleCursor(' ', 153, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(153, 250, position), backgroundColor: handleTextBackgroundColor(154, 250, position, false)}}>{handleCursor('H', 154, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(154, 250, position), backgroundColor: handleTextBackgroundColor(155, 250, position, false)}}>{handleCursor('e', 155, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(155, 250, position), backgroundColor: handleTextBackgroundColor(156, 250, position, false)}}>{handleCursor(' ', 156, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(156, 250, position), backgroundColor: handleTextBackgroundColor(157, 250, position, false)}}>{handleCursor('w', 157, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(157, 250, position), backgroundColor: handleTextBackgroundColor(158, 250, position, false)}}>{handleCursor('a', 158, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(158, 250, position), backgroundColor: handleTextBackgroundColor(159, 250, position, false)}}>{handleCursor('s', 159, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(159, 250, position), backgroundColor: handleTextBackgroundColor(160, 250, position, false)}}>{handleCursor(' ', 160, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(160, 250, position), backgroundColor: handleTextBackgroundColor(161, 250, position, true)}}>{handleCursor('s', 161, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(161, 250, position), backgroundColor: handleTextBackgroundColor(162, 250, position, true)}}>{handleCursor('i', 162, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(162, 250, position), backgroundColor: handleTextBackgroundColor(163, 250, position, true)}}>{handleCursor('t', 163, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(163, 250, position), backgroundColor: handleTextBackgroundColor(164, 250, position, true)}}>{handleCursor('t', 164, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(164, 250, position), backgroundColor: handleTextBackgroundColor(165, 250, position, true)}}>{handleCursor('i', 165, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(165, 250, position), backgroundColor: handleTextBackgroundColor(166, 250, position, true)}}>{handleCursor('n', 166, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(166, 250, position), backgroundColor: handleTextBackgroundColor(167, 250, position, true)}}>{handleCursor('g', 167, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(167, 250, position), backgroundColor: handleTextBackgroundColor(168, 250, position, false)}}>{handleCursor(' ', 168, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(168, 250, position), backgroundColor: handleTextBackgroundColor(169, 250, position, false)}}>{handleCursor('i', 169, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(169, 250, position), backgroundColor: handleTextBackgroundColor(170, 250, position, false)}}>{handleCursor('n', 170, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(170, 250, position), backgroundColor: handleTextBackgroundColor(171, 250, position, false)}}>{handleCursor(' ', 171, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(171, 250, position), backgroundColor: handleTextBackgroundColor(172, 250, position, false)}}>{handleCursor('a', 172, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(172, 250, position), backgroundColor: handleTextBackgroundColor(173, 250, position, false)}}>{handleCursor(' ', 173, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(173, 250, position), backgroundColor: handleTextBackgroundColor(174, 250, position, false)}}>{handleCursor('t', 174, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(174, 250, position), backgroundColor: handleTextBackgroundColor(175, 250, position, false)}}>{handleCursor('r', 175, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(175, 250, position), backgroundColor: handleTextBackgroundColor(176, 250, position, false)}}>{handleCursor('a', 176, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(176, 250, position), backgroundColor: handleTextBackgroundColor(177, 250, position, false)}}>{handleCursor('s', 177, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(177, 250, position), backgroundColor: handleTextBackgroundColor(178, 250, position, false)}}>{handleCursor('h', 178, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(178, 250, position), backgroundColor: handleTextBackgroundColor(179, 250, position, false)}}>{handleCursor(' ', 179, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(179, 250, position), backgroundColor: handleTextBackgroundColor(180, 250, position, false)}}>{handleCursor('c', 180, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(180, 250, position), backgroundColor: handleTextBackgroundColor(181, 250, position, false)}}>{handleCursor('a', 181, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(181, 250, position), backgroundColor: handleTextBackgroundColor(182, 250, position, false)}}>{handleCursor('n', 182, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(182, 250, position), backgroundColor: handleTextBackgroundColor(183, 250, position, false)}}>{handleCursor(' ', 183, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(183, 250, position), backgroundColor: handleTextBackgroundColor(184, 250, position, false)}}>{handleCursor('w', 184, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(184, 250, position), backgroundColor: handleTextBackgroundColor(185, 250, position, false)}}>{handleCursor('i', 185, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(185, 250, position), backgroundColor: handleTextBackgroundColor(186, 250, position, false)}}>{handleCursor('t', 186, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(186, 250, position), backgroundColor: handleTextBackgroundColor(187, 250, position, false)}}>{handleCursor('h', 187, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(187, 250, position), backgroundColor: handleTextBackgroundColor(188, 250, position, false)}}>{handleCursor(' ', 188, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(188, 250, position), backgroundColor: handleTextBackgroundColor(189, 250, position, false)}}>{handleCursor('h', 189, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(189, 250, position), backgroundColor: handleTextBackgroundColor(190, 250, position, false)}}>{handleCursor('i', 190, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(190, 250, position), backgroundColor: handleTextBackgroundColor(191, 250, position, false)}}>{handleCursor('g', 191, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(191, 250, position), backgroundColor: handleTextBackgroundColor(192, 250, position, false)}}>{handleCursor('h', 192, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(192, 250, position), backgroundColor: handleTextBackgroundColor(193, 250, position, false)}}>{handleCursor(' ', 193, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(193, 250, position), backgroundColor: handleTextBackgroundColor(194, 250, position, true)}}>{handleCursor('s', 194, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(194, 250, position), backgroundColor: handleTextBackgroundColor(195, 250, position, true)}}>{handleCursor('t', 195, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(195, 250, position), backgroundColor: handleTextBackgroundColor(196, 250, position, true)}}>{handleCursor('r', 196, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(196, 250, position), backgroundColor: handleTextBackgroundColor(197, 250, position, true)}}>{handleCursor('e', 197, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(197, 250, position), backgroundColor: handleTextBackgroundColor(198, 250, position, true)}}>{handleCursor('e', 198, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(198, 250, position), backgroundColor: handleTextBackgroundColor(199, 250, position, true)}}>{handleCursor('t', 199, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(199, 250, position), backgroundColor: handleTextBackgroundColor(200, 250, position, false)}}>{handleCursor(' ', 200, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(200, 250, position), backgroundColor: handleTextBackgroundColor(201, 250, position, false)}}>{handleCursor('c', 201, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(201, 250, position), backgroundColor: handleTextBackgroundColor(202, 250, position, false)}}>{handleCursor('l', 202, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(202, 250, position), backgroundColor: handleTextBackgroundColor(203, 250, position, false)}}>{handleCursor('a', 203, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(203, 250, position), backgroundColor: handleTextBackgroundColor(204, 250, position, false)}}>{handleCursor('s', 204, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(204, 250, position), backgroundColor: handleTextBackgroundColor(205, 250, position, false)}}>{handleCursor('s', 205, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(205, 250, position), backgroundColor: handleTextBackgroundColor(206, 250, position, false)}}>{handleCursor('.', 206, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(206, 250, position), backgroundColor: handleTextBackgroundColor(207, 250, position, false)}}>{handleCursor(' ', 207, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(207, 250, position), backgroundColor: handleTextBackgroundColor(208, 250, position, false)}}>{handleCursor('H', 208, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(208, 250, position), backgroundColor: handleTextBackgroundColor(209, 250, position, false)}}>{handleCursor('e', 209, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(209, 250, position), backgroundColor: handleTextBackgroundColor(210, 250, position, false)}}>{handleCursor(' ', 210, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(210, 250, position), backgroundColor: handleTextBackgroundColor(211, 250, position, false)}}>{handleCursor('l', 211, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(211, 250, position), backgroundColor: handleTextBackgroundColor(212, 250, position, false)}}>{handleCursor('i', 212, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(212, 250, position), backgroundColor: handleTextBackgroundColor(213, 250, position, false)}}>{handleCursor('k', 213, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(213, 250, position), backgroundColor: handleTextBackgroundColor(214, 250, position, false)}}>{handleCursor('e', 214, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(214, 250, position), backgroundColor: handleTextBackgroundColor(215, 250, position, false)}}>{handleCursor('d', 215, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(215, 250, position), backgroundColor: handleTextBackgroundColor(216, 250, position, false)}}>{handleCursor(' ', 216, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(216, 250, position), backgroundColor: handleTextBackgroundColor(217, 250, position, false)}}>{handleCursor('t', 217, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(217, 250, position), backgroundColor: handleTextBackgroundColor(218, 250, position, false)}}>{handleCursor('o', 218, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(218, 250, position), backgroundColor: handleTextBackgroundColor(219, 250, position, false)}}>{handleCursor(' ', 219, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(219, 250, position), backgroundColor: handleTextBackgroundColor(220, 250, position, false)}}>{handleCursor('p', 220, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(220, 250, position), backgroundColor: handleTextBackgroundColor(221, 250, position, false)}}>{handleCursor('l', 221, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(221, 250, position), backgroundColor: handleTextBackgroundColor(222, 250, position, false)}}>{handleCursor('a', 222, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(222, 250, position), backgroundColor: handleTextBackgroundColor(223, 250, position, false)}}>{handleCursor('y', 223, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(223, 250, position), backgroundColor: handleTextBackgroundColor(224, 250, position, false)}}>{handleCursor(' ', 224, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(224, 250, position), backgroundColor: handleTextBackgroundColor(225, 250, position, false)}}>{handleCursor('w', 225, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(225, 250, position), backgroundColor: handleTextBackgroundColor(226, 250, position, false)}}>{handleCursor('i', 226, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(226, 250, position), backgroundColor: handleTextBackgroundColor(227, 250, position, false)}}>{handleCursor('t', 227, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(227, 250, position), backgroundColor: handleTextBackgroundColor(228, 250, position, false)}}>{handleCursor('h', 228, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(228, 250, position), backgroundColor: handleTextBackgroundColor(229, 250, position, false)}}>{handleCursor(' ', 229, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(229, 250, position), backgroundColor: handleTextBackgroundColor(230, 250, position, false)}}>{handleCursor('w', 230, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(230, 250, position), backgroundColor: handleTextBackgroundColor(231, 250, position, false)}}>{handleCursor('o', 231, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(231, 250, position), backgroundColor: handleTextBackgroundColor(232, 250, position, false)}}>{handleCursor('r', 232, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(232, 250, position), backgroundColor: handleTextBackgroundColor(233, 250, position, false)}}>{handleCursor('d', 233, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(233, 250, position), backgroundColor: handleTextBackgroundColor(234, 250, position, false)}}>{handleCursor('s', 234, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(234, 250, position), backgroundColor: handleTextBackgroundColor(235, 250, position, false)}}>{handleCursor(' ', 235, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(235, 250, position), backgroundColor: handleTextBackgroundColor(236, 250, position, false)}}>{handleCursor('i', 236, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(236, 250, position), backgroundColor: handleTextBackgroundColor(237, 250, position, false)}}>{handleCursor('n', 237, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(237, 250, position), backgroundColor: handleTextBackgroundColor(238, 250, position, false)}}>{handleCursor(' ', 238, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(238, 250, position), backgroundColor: handleTextBackgroundColor(239, 250, position, false)}}>{handleCursor('t', 239, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(239, 250, position), backgroundColor: handleTextBackgroundColor(240, 250, position, false)}}>{handleCursor('h', 240, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(240, 250, position), backgroundColor: handleTextBackgroundColor(241, 250, position, false)}}>{handleCursor('e', 241, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(241, 250, position), backgroundColor: handleTextBackgroundColor(242, 250, position, false)}}>{handleCursor(' ', 242, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(242, 250, position), backgroundColor: handleTextBackgroundColor(243, 250, position, true)}}>{handleCursor('b', 243, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(243, 250, position), backgroundColor: handleTextBackgroundColor(244, 250, position, true)}}>{handleCursor('a', 244, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(244, 250, position), backgroundColor: handleTextBackgroundColor(245, 250, position, true)}}>{handleCursor('t', 245, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(245, 250, position), backgroundColor: handleTextBackgroundColor(246, 250, position, true)}}>{handleCursor('h', 246, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(246, 250, position), backgroundColor: handleTextBackgroundColor(247, 250, position, true)}}>{handleCursor('t', 247, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(247, 250, position), backgroundColor: handleTextBackgroundColor(248, 250, position, true)}}>{handleCursor('u', 248, 250, position)}</span>
                <span style = {{opacity: handleTextOpacity(248, 250, position), backgroundColor: handleTextBackgroundColor(249, 250, position, true)}}>{handleCursor('b', 249, 250, position)}</span>
            </>
        )
    }

    const Graphic1 = (position) => {
        return (
            <img className = {classes.graphic} style = {{opacity: handleImageOpacity(position)}} src = {Graphic_1} alt = '' />
        )
    }

    const Graphic2 = (position) => {
        return (
            <img className = {classes.graphic} style = {{opacity: handleImageOpacity(position)}} src = {Graphic_2} alt = '' />
        )
    }

    const EventButton = (position) => {
        return (
            <div>
                <Button style = {{opacity: handleButtonOpacity(position), borderColor: 'black'}} variant = 'outlined' component = {Link} to = '/events'>
                    <span className = {classes.button} >
                        Events
                    </span>
                </Button>
            </div>
        )
    }

    const ContactButton = (position) => {
        return (
            <div>
                <Button style = {{opacity: handleButtonOpacity(position), borderColor: 'black'}} variant = 'outlined' component = {Link} to = '/contact'>
                    <span className = {classes.button} >
                        Contact
                    </span>
                </Button>
            </div>
        )
    }

    const Copyright = () => {
        return (
            <span className = {classes.copyright}>
                2023 MK Ultra Productions. All Rights Reserved
            </span>
        )   
    }

    const startPosition1 = 0.1;
    const endPosition1 = 1.1;

    const position1 = {startPosition: startPosition1, endPosition: endPosition1};

    const startPosition2 = 1.5;
    const endPosition2 = 2.5;

    const position2 = {startPosition: startPosition2, endPosition: endPosition2};

    const startPosition3 = 3.5;
    const endPosition3 = 4.5;

    const position3 = {startPosition: startPosition3, endPosition: endPosition3};

    const redactStartPosition = 4.75;
    const redactEndPosition = 5.75;

    const eventStartPosition = 5.25;
    const eventEndPosition = 5.75;

    const handleBorder = 0;

    useEffect(() => {
        window.scrollTo(0, 0);

        handleScroll();

        window.addEventListener("scroll", handleScroll);
        
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    },[]);

    return (
        <div>
            <div className = {classes.home}>
                <div className = {classes.page1}>
                    <Box className = {classes.header} border = {handleBorder}>
                        <Box className = {classes.iconBox} ml = {3} border = {handleBorder}>
                            <div className = {classes.icon} />
                        </Box>
                        <Box style = {{opacity: 0}} border = {handleBorder}>
                            <Typewriter onInit = {(typewriter) => {typewriter.start()}} />
                        </Box>
                        <Box flexGrow = {1} classes = {classes.typewriterBox} border = {handleBorder}>
                            <Typewriter onInit = {(typewriter) => {handleHeader(typewriter)}} />
                        </Box>
                        <Box className = {classes.iconBox} mr = {3} border = {handleBorder}>
                            <Icon />
                        </Box>
                    </Box>
                    <Box className = {classes.content1} border = {handleBorder}>
                        <Box className = {classes.textBox1} border = {handleBorder}>
                            <div>
                                <Text1 startPosition = {startPosition1} endPosition = {endPosition1} />
                                <span style = {{opacity: handleCursorOpacity(82, 83, position1, position2)}}>{handleCursor('cursor', 83, 83, position1)}</span>
                            </div>
                        </Box>
                        <Box className = {classes.graphicBox} border = {handleBorder}>
                            <Graphic1 startPosition = {startPosition1} endPosition = {endPosition1} />
                        </Box>
                        <Box className = {classes.graphicBox} order = {handleOrder(0)} border = {handleBorder}>
                            <Graphic2 startPosition = {startPosition2} endPosition = {endPosition2} />
                        </Box>
                        <Box className = {classes.textBox1} order = {handleOrder(1)} border = {handleBorder}>
                            <div>
                                <Text2 startPosition = {startPosition2} endPosition = {endPosition2} />
                                <span style = {{opacity: handleCursorOpacity(88, 89, position2, position3)}}>{handleCursor('cursor', 89, 89, position2)}</span>
                            </div>
                        </Box>
                    </Box>
                    <Box className = {classes.footer} border = {handleBorder}>
                        <div>
                            <span id = 'char' style = {{opacity: 0}}>a</span>
                            <span id = 'space' style = {{opacity: 0}}> </span>
                            <span style = {{opacity: 0}}>b</span>
                        </div>
                    </Box>  
                </div>
            </div>
            <div className = {classes.home}>
                <div className = {classes.page2}>
                    <Box className = {classes.header} border = {handleBorder} />
                    <Box className = {classes.content2} border = {handleBorder}>
                        <Box className = {classes.textBox2} border = {handleBorder}>
                            <div>
                                <Text3 startPosition = {startPosition3} endPosition = {endPosition3} redactStartPosition = {redactStartPosition} redactEndPosition = {redactEndPosition} />
                                <span style = {{opacity: handleTextOpacity(249, 250, position3), backgroundColor: handleTextBackgroundColor(250, 250, position3, false)}}>{handleCursor('cursor', 250, 250, position3)}</span>
                            </div>
                        </Box>
                        <Box className = {classes.buttonBox} border = {handleBorder}>
                            <EventButton startPosition = {eventStartPosition} endPosition = {eventEndPosition} />
                            <ContactButton startPosition = {eventStartPosition} endPosition = {eventEndPosition} />
                        </Box>
                    </Box>
                    <Box className = {classes.footer} border = {handleBorder}>
                        <Copyright />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Home;