import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {makeStyles} from '@mui/styles';

import Typewriter from 'typewriter-effect';

import Icon_1 from '../Graphics/MK Ultra Productions Written Logo.png';
import Poster_1 from '../Graphics/MK Ultra Productions Facelift Poster.jpeg';


const useStyles = makeStyles((theme) => ({    
    events: {
        height: window.innerHeight,
    },

    header: {
        height: window.innerHeight * 0.10,
        width: window.innerWidth,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Courier',
        fontSize: '2.75rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
        },
    },

    footer: {
        height: window.innerHeight * 0.10,
        width: window.innerWidth,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Courier',
        fontSize: '2.75rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
        },
    },

    typewriterBox: {
        height: '100%',
    },

    iconBox: {
        height: window.innerHeight * 0.10 * 0.61,
        width: window.innerHeight * 0.10 * 0.61,
        [theme.breakpoints.down('md')]: {
            height: window.innerHeight * 0.10 * 0.31,
            width: window.innerHeight * 0.10 * 0.31,
        },
        [theme.breakpoints.down('sm')]: {
            height: window.innerHeight * 0.10 * 0.25,
            width: window.innerHeight * 0.10 * 0.25,
        },
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    },

    content: {
        height: window.innerHeight * 0.80,
        width: window.innerWidth,
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'space-evenly',
        justifyContent: 'space-evenly',
    },

    posterBox: {
        height: '80%',
        width: '40%',
        [theme.breakpoints.down('md')]: {
            height: '40%',
            width: '80%',
        },
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    },

    eventBox: {
        height: '80%',
        width: '40%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Courier',
        fontSize: '2.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
            height: '40%',
            width: '80%',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
    },

    poster: {
        maxHeight: '100%',
        maxWidth: '100%',
    },

    icon: {
        maxHeight: '100%',
        maxWidth: '100%',
    },

    button: {
        fontFamily: 'Courier',
        fontSize: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        color: 'black',
    },

    copyright: {
        fontFamily: 'Courier',
        fontSize: '0.75rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.50rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.25rem',
        },
    },
}));

const Events = () => {
    const classes = useStyles();

    const handleHeader = (typewriter) => {
        typewriter.typeString("Events")
        typewriter.start();
    }

    const Icon = () => {
        return (
            <div>
                <Button className = {classes.icon} component = {Link} to = '/'>
                    <img className = {classes.icon} src = {Icon_1} alt = '' />
                </Button>
            </div>
        )
    }

    const Poster = () => {
        return (
            <img className = {classes.poster} src = {Poster_1} alt = '' />
        )
    }

    const Event = () => {
        return (
            <div>
                <div>             
                    <span>
                        Club Above
                    </span>
                    <br />
                    <span>
                        215 N Main St
                    </span>
                    <br />
                    <span>
                        Ann Arbor, MI
                    </span>
                    <br />
                    <br />
                    <span>
                        March 30th
                    </span>
                </div>
                <br />
                <div>
                    <Button style = {{borderColor: 'black'}} variant = 'outlined' href = 'https://mkultraproductions.ticketspice.com/facelift'>
                        <span className = {classes.button}>
                            Buy Tickets
                        </span>
                    </Button>
                </div>
            </div>
        )
    }

    const Copyright = () => {
        return (
            <span className = {classes.copyright}>
                2023 MK Ultra Productions. All Rights Reserved
            </span>
        )   
    }

    const handleBorder = 0;

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <div>
            <div className = {classes.events}>
                <Box className = {classes.header} border = {handleBorder}>
                    <Box className = {classes.iconBox} ml = {3} border = {handleBorder}>
                        <div className = {classes.icon} />
                    </Box>
                    <Box style = {{opacity: 0}} border = {handleBorder}>
                        <Typewriter onInit = {(typewriter) => {typewriter.start()}} />
                    </Box>
                    <Box flexGrow = {1} classes = {classes.typewriterBox} border = {handleBorder}>
                        <Typewriter onInit = {(typewriter) => {handleHeader(typewriter)}} />
                    </Box>
                    <Box className = {classes.iconBox} mr = {3} border = {handleBorder}>
                        <Icon />
                    </Box>
                </Box>
                <Box className = {classes.content} border = {handleBorder}>
                    <Box className = {classes.posterBox} border = {handleBorder}>
                        <Poster />
                    </Box>
                    <Box className = {classes.eventBox} border = {handleBorder}>
                        <Event />
                    </Box>
                </Box>
                <Box className = {classes.footer} border = {handleBorder}>
                    <Copyright />
                </Box>
            </div>
        </div>
    )
}

export default Events;