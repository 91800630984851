import React, {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import {ThemeProvider} from '@mui/material/styles';

import App from './App.js';
import theme from './theme.js'

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <ThemeProvider theme = {theme}>
      <App />
    </ThemeProvider>
  </StrictMode>
);