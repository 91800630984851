import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Home from './Components/Pages/Home.js';
import Events from './Components/Pages/Events.js';
import Contact from './Components/Pages/Contact.js';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path = '/' element = {<Home />} />
        <Route path = '/events' element = {<Events />} />
        <Route path = '/contact' element = {<Contact />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
