import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {makeStyles} from '@mui/styles';

import Typewriter from 'typewriter-effect';

import Icon_1 from '../Graphics/MK Ultra Productions Written Logo.png';


const useStyles = makeStyles((theme) => ({    
    events: {
        height: window.innerHeight,
    },

    header: {
        height: window.innerHeight * 0.10,
        width: window.innerWidth,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Courier',
        fontSize: '2.75rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
        },
    },

    footer: {
        height: window.innerHeight * 0.10,
        width: window.innerWidth,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Courier',
        fontSize: '2.75rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
        },
    },

    typewriterBox: {
        height: '100%',
    },

    iconBox: {
        height: window.innerHeight * 0.10 * 0.61,
        width: window.innerHeight * 0.10 * 0.61,
        [theme.breakpoints.down('md')]: {
            height: window.innerHeight * 0.10 * 0.31,
            width: window.innerHeight * 0.10 * 0.31,
        },
        [theme.breakpoints.down('sm')]: {
            height: window.innerHeight * 0.10 * 0.25,
            width: window.innerHeight * 0.10 * 0.25,
        },
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    },

    content: {
        height: window.innerHeight * 0.80,
        width: window.innerWidth,
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'space-evenly',
        justifyContent: 'space-evenly',
    },

    textBox: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Courier',
        fontSize: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },

    icon: {
        maxHeight: '100%',
        maxWidth: '100%',
    },

    link: {
        fontFamily: 'Courier',
        fontSize: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        color: 'black',
        textDecoration: 'none',
    },

    copyright: {
        fontFamily: 'Courier',
        fontSize: '0.75rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.50rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.25rem',
        },
    },
}));

const Contact = () => {
    const classes = useStyles();

    const handleHeader = (typewriter) => {
        typewriter.typeString("Contact")
        typewriter.start();
    }

    const Icon = () => {
        return (
            <div>
                <Button className = {classes.icon} component = {Link} to = '/'>
                    <img className = {classes.icon} src = {Icon_1} alt = '' />
                </Button>
            </div>
        )
    }

    const ContactInfo = () => {
        return (
            <div>
                <Box className = {classes.textBox} border = {handleBorder}>
                    <Box border = {handleBorder}>
                        <span>Email: </span>
                        <Link className = {classes.link} to = 'mailto:productionbymkultra@gmail.com'>
                            <span>productionbymkultra@gmail.com</span>
                        </Link>
                    </Box>
                    <br />                
                    <Box border = {handleBorder}>
                        <span>Instagram: </span>
                        <Link className = {classes.link} to = 'https://instagram.com/productionbymkultra'>
                            <span>productionbymkultra</span>
                        </Link>
                    </Box>
                    <br />   
                    <Box border = {handleBorder}>
                        <span>TikTok: </span>
                        <Link className = {classes.link} to = 'https://tiktok.com/@mk_ultra_productions'>
                            <span>mk_ultra_productions</span>
                        </Link>
                    </Box>
                </Box>
            </div>
        )
    }

    const Copyright = () => {
        return (
            <span className = {classes.copyright}>
                2023 MK Ultra Productions. All Rights Reserved
            </span>
        )   
    }

    const handleBorder = 0;

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <div>
            <div className = {classes.events}>
                <Box className = {classes.header} border = {handleBorder}>
                    <Box className = {classes.iconBox} ml = {3} border = {handleBorder}>
                        <div className = {classes.icon} />
                    </Box>
                    <Box style = {{opacity: 0}} border = {handleBorder}>
                        <Typewriter onInit = {(typewriter) => {typewriter.start()}} />
                    </Box>
                    <Box flexGrow = {1} classes = {classes.typewriterBox} border = {handleBorder}>
                        <Typewriter onInit = {(typewriter) => {handleHeader(typewriter)}} />
                    </Box>
                    <Box className = {classes.iconBox} mr = {3} border = {handleBorder}>
                        <Icon />
                    </Box>
                </Box>
                <Box className = {classes.content} border = {handleBorder}>
                    <ContactInfo />
                </Box>
                <Box className = {classes.footer} border = {handleBorder}>
                    <Copyright />
                </Box>
            </div>
        </div>
    )
}

export default Contact;